$(document).ready(function () {

    // Get started!
    TweenMax.to($('section.header .header-text'), 0.5, {css: {opacity: 1, marginLeft:'0px'}, delay: 0, ease:Quad.easeInOut});
});


var map = null;
var markers = [];

function initMap() {


    if ($('.map-wrapper').length > 0) {
        bounds = new google.maps.LatLngBounds();

        projectMarker = new google.maps.MarkerImage(
            '/assets/img/marker.png',
            null,
            null,
            null, //size
            new google.maps.Size(44, 75)
        );


        var mapOptions = {
            zoom: 16,
            center: new google.maps.LatLng(47.363548, 8.551065),
            disableDefaultUI: false,
            styles: [{
                "featureType": "administrative",
                "elementType": "labels.text.fill",
                "stylers": [{"color": "#2e2e2e"}]
            }, {
                "featureType": "administrative.country",
                "elementType": "geometry.fill",
                "stylers": [{"visibility": "off"}, {"color": "#fc6e6e"}]
            }, {
                "featureType": "administrative.province",
                "elementType": "geometry.fill",
                "stylers": [{"visibility": "off"}, {"hue": "#008aff"}]
            }, {
                "featureType": "landscape",
                "elementType": "all",
                "stylers": [{"color": "#e8e7e7"}, {"visibility": "on"}]
            }, {
                "featureType": "landscape",
                "elementType": "labels.text",
                "stylers": [{"visibility": "on"}, {"lightness": "-64"}, {"weight": "0.01"}]
            }, {
                "featureType": "poi",
                "elementType": "all",
                "stylers": [{"visibility": "off"}, {"saturation": "2"}]
            }, {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [{"visibility": "on"}, {"lightness": "34"}, {"saturation": "-60"}, {"gamma": "1"}]
            }, {
                "featureType": "poi.attraction",
                "elementType": "labels",
                "stylers": [{"visibility": "simplified"}]
            }, {
                "featureType": "poi.park",
                "elementType": "labels",
                "stylers": [{"visibility": "simplified"}]
            }, {
                "featureType": "road",
                "elementType": "all",
                "stylers": [{"saturation": -100}, {"lightness": 45}]
            }, {
                "featureType": "road",
                "elementType": "labels.text",
                "stylers": [{"saturation": "0"}, {"lightness": "0"}, {"gamma": "0.42"}]
            }, {
                "featureType": "road.highway",
                "elementType": "all",
                "stylers": [{"visibility": "simplified"}, {"saturation": "26"}, {"gamma": "1"}, {"lightness": "-3"}]
            }, {
                "featureType": "road.highway",
                "elementType": "labels.text",
                "stylers": [{"lightness": "0"}, {"gamma": "1.00"}]
            }, {
                "featureType": "road.arterial",
                "elementType": "labels.icon",
                "stylers": [{"visibility": "off"}]
            }, {
                "featureType": "transit",
                "elementType": "all",
                "stylers": [{"visibility": "off"}]
            }, {
                "featureType": "transit",
                "elementType": "labels",
                "stylers": [{"visibility": "simplified"}]
            }, {"featureType": "water", "elementType": "all", "stylers": [{"color": "#8dc2d8"}, {"visibility": "on"}]}],
            zoomControl: true,
            zoomControlOptions: {
                style: google.maps.ZoomControlStyle.SMALL,
                position: google.maps.ControlPosition.LEFT_BOTTOM
            },
            mapTypeControlOptions: {
                mapTypeIds: ['gray_map', google.maps.MapTypeId.SATELLITE]
            }
        };

        // Map in Content
        var containerId = 'cs-map';
        map = new google.maps.Map(document.getElementById(containerId), mapOptions);

        // set gray style
        //map.mapTypes.set('gray_map',
        // new google.maps.StyledMapType([{stylers: [{saturation: -90}]}],
        // {name: "Karte"}));
        //map.setMapTypeId('gray_map');

        // set marker when map has loaded
        google.maps.event.addListenerOnce(map, 'tilesloaded', function () {
            setMarkers();
        });
    }
}

/*if($('#cs-map').length > 0) {
	google.maps.event.addDomListener(window, 'load', initialize);
}*/


function setMarkers() {

    marker = new google.maps.Marker({
        position: new google.maps.LatLng(47.363548, 8.551065),
        map: map,
        icon: projectMarker,
    });
}
